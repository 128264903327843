import '@mdi/font/css/materialdesignicons.css' // Material Design Icons
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Material Icons
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/es5/locale/it';
import themes from './themes.js'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {it},
        current: 'it'
    },
    icons: {
        iconfont: 'mdi' || 'mi'
    },
    options: {
        customProperties: true
    },
    theme: {dark: false, themes}
});
