<template>
  <v-app>
    <NavigationBar />
    <v-main>
      <v-container fluid style="position: fixed; z-index: 1;" class="d-flex justify-center">
        <v-alert
          :value="develop"
          border="left"
          :prominent="$vuetify.breakpoint.mdAndUp"
          dense                                        
          transition="scale-transition"
          type="warning"
          class="mx-6 mt-2 text-body-2 text-md-body-1"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ $t('alerts.develop') }}
            </v-col>
            <v-col class="shrink">
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" outlined @click="develop = !develop">{{$t('basics.close')}}</v-btn>
              <v-btn v-else icon @click="develop = !develop"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-alert>        
      </v-container>        
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <FloatingUpButton />
    </v-main>
    <Footer />
    <v-snackbar
      app
      v-model="snackbar"
      :timeout="3000"
      :color="color"
      :min-width="50"
      bottom
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-bottom-sheet
      v-if="!$cookies.get('cookieBanner')"
      hide-overlay
      v-model="cookie"
      persistent
    >
      <v-sheet>
        <v-banner color="white">
          <v-avatar slot="icon" color="primary" size="40">
            <v-icon icon="mdi-lock" color="white"> mdi-cookie </v-icon>
          </v-avatar>
          <div class="d-flex align-center">
            <div v-html="$t('basics.cookie_consent')"></div>
            <v-btn @click="hideCookieBar" text color="primary">
              {{ $t("basics.close") }}
            </v-btn>
          </div>
        </v-banner>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import NavigationBar from "./components/navigation/NavigationBar";
import FloatingUpButton from "@/components/buttons/FloatingUpButton";
import Footer from "./components/Footer";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavigationBar,
    FloatingUpButton,
    Footer,
  },
  data() {
    return {
      cookie: true,
      develop: false,
      snackbar: false,      
      sheet: true,
    };
  },
  computed: {
    ...mapGetters(["show", "text", "color"]),
  },
  methods: {
    toggle(value){
      return !value;
    },
    hideCookieBar() {
      this.cookie = !this.cookie;

      if (!this.$cookies.get("cookieBanner")) {
        this.$cookies.set("cookieBanner", true, "30d"); //return this
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.snackbar = value;
        console.log(this.snackbar);
        return this.$store.dispatch("hideSnackbar");
      }
      return null;
    },
  },
  mounted(){
    this.develop = this.toggle(this.develop);
    setTimeout(() => this.develop = this.develop ? this.toggle(this.develop) : false,5000)
  }
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
//GENERIC STYLE FOR AUTOCOMPLETE MENU GOING OVER APP BAR
.v-menu__content{  
  z-index: 1 !important;
}
</style>

