<template>
  <v-list dense>
    <v-list-item-group v-model="selectedItem" mandatory color="primary">     
      <DrawerListGroup v-for="group in groups" :key="group.title" :group="group" @route-indexes="routeIndexes($event)"/>       
      <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import DrawerListGroup from './DrawerListGroup'

export default {
  components: {
    DrawerListGroup
  },
  props: ["items", "groups"],
  data: () => ({
    selectedItem: []
  }),
  methods: {
    //HARD CODED Routing on list-group-head click 
    routeIndexes(id) {     
            console.log(id);

      if( this.$route.path === this.groups[id].to) return      
      
      this.$router.push(this.groups[id].to)      
    }
  }
};
</script>