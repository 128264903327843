let home = [
    {
        text: "app-bar.home",
        href: "/"
    }
]

let storytelling = [
    ...home,
    {
        text: "app-bar.aldo-moro",
        href: "/aldomoro",
    }
]

let edition = [
    ...home,
    {
        text: "app-bar.works",
        href: "/edition",
    }
];

let about = [
    ...home,
    {
        text: "app-bar.project",
        href: "/about",
    }
];
let visualizations = [
    ...home,
    {
        text: "app-bar.visualizations",
        href: "/visualizations",
    }
]

let indexes = [
    ...edition,
    {
        text: "drawer-menu.indexes.title",
        href: "/edition/browse/indexes",
    }
];

let editorial_plan = [
    ...edition,
    {
        text: 'drawer-menu.ed_plan',
        href: "/edition/browse/editorial_plan"
    }
]

let advance_search = [
    ...edition,
    {
        text: 'drawer-menu.advance_search',
        href: "/edition/browse/advance_search"
    }
]

let work = [
    ...edition,
    {
        text: 'collection.work.title',
        href: "/edition/browse/work"
    }
]

let people = [
    ...indexes,
    {
        text: "drawer-menu.indexes.people",
        href: "/edition/browse/indexes/people",
    }
];

let places = [
    ...indexes,
    {
        text: "drawer-menu.indexes.places",
        href: "/edition/browse/indexes/places",
    }
];

let organizations = [
    ...indexes,
    {
        text: "drawer-menu.indexes.org",
        href: "/edition/browse/indexes/organizations",
    }
];

let bibliography = [
    ...indexes,
    {
        text: "drawer-menu.indexes.bib",
        href: "/edition/browse/indexes/bibliography",
    }
];

let workflow = [
    ...about,
    {
        text: "about.workflow.title",
        href: "/about/docs/workflow",
    }
];

let principles = [
    ...about,
    {
        text: "about.principles.title",
        href: "/about/docs/principles",
    }
];

let models = [
    ...about,
    {
        text: "about.models.title",
        href: "/about/docs/models",
    }
];

let processing = [
    ...about,
    {
        text: "about.processing.title",
        href: "/about/docs/processing",
    }
];

let site = [
    ...about,
    {
        text: "about.site.title",
        href: "/about/docs/site",
    }
];

let results = [
    ...about,
    {
        text: "about.results.title",
        href: "/about/docs/results"
    }
]

let contacts = [
    ...home,
    {
        text: "contacts.title",
        href: "/contacts",
    }
];

let credits = [
    ...home,
    {
        text: "credits.title",
        href: "/credits",
    }
];

let terms = [
    ...home,
    {
        text: "terms.title",
        href: "/terms",
    }
];


export default {
    storytelling, 
    edition,
    about,
    visualizations,
    indexes,
    editorial_plan,
    advance_search,
    work,
    people,
    places,
    organizations,
    bibliography,
    workflow,
    principles,
    models,
    processing,
    site,
    results,
    contacts,
    credits,
    terms
}