<template>
    <v-alert
    class="mx-4"
    border="left"
    outlined
    text
    :prominent="$vuetify.breakpoint.mdAndUp"
    :dense="$vuetify.breakpoint.mdAndDown"
    type="success"
    elevation=2
    icon="mdi-lightbulb"
    >{{ $t(introSeeAlso) }}
        <router-link
        :to="$t(link)">
        {{ $t(seeAlso) }}
        </router-link>
    </v-alert>
</template>

<script>
export default {
    props: ['introSeeAlso', 'seeAlso', 'link'],
}
</script>