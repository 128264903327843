<template>
  <div>
    <v-list-group         
      no-action
    >
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>{{ group.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title @click.prevent="$emit('route-indexes',group.id)">{{ $t(group.title) }}</v-list-item-title>
      </template>

      <v-list-item
        v-for="child in group.children"
        :key="child.id"
        :to="child.to"
        link
        @click="selectedIndex(child.id)"
      >
        <v-list-item-title v-text="$t(child.title)"></v-list-item-title>

        <v-list-item-icon>
          <v-icon v-text="child.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
export default {    
    data() {
      return {
        selectedId: ''
      }
    },
    props: ['group'],
    emit: ['route-indexes','selected-index'],    
    provide() {
      return {
        id: this.selectedId
      }
    },
    methods: {
      selectedIndex(id){
        this.selectedId = id;
      }
    }  
}
</script>

