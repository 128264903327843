import colors from 'vuetify/lib/util/colors'

export default {
        light: {
          primary: "#005a96",
          complementary: "#963c00",
          analogousFirst: "#009687",
          analogousSecond: "#000f96",
          triadicFirst: "#3c0096",
          triadicSecond: "#96005a",
          secondary: "#757575",
          secondaryLighten: "#BDBDBD",
          accent: colors.shades.black,
          error: colors.red.accent3,
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',          
        },
        dark: {
          primary: colors.blue.lighten3,
        }      
}