import { render, staticRenderFns } from "./WorkCard.vue?vue&type=template&id=bfd9136a&scoped=true&"
import script from "./WorkCard.vue?vue&type=script&lang=js&"
export * from "./WorkCard.vue?vue&type=script&lang=js&"
import style0 from "./WorkCard.vue?vue&type=style&index=0&id=bfd9136a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd9136a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VDivider,VLazy,VSheet})
