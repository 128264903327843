<template>
    <base-section-heading 
        title="Visualizzazioni">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in! Asperiores, impedit libero. Veniam rerum saepe unde nihil possimus quibusdam esse accusamus mollitia magni fuga.
    </base-section-heading>  
</template>

<script>
export default {
    
}
</script>