<template>
  <v-navigation-drawer id="document-drawer" class="hidden-sm-and-down" :mini-variant.sync="mini" app floating clipped permanent width="400">
    <!-- <v-system-bar height="10"></v-system-bar> -->

    <v-list-item class="px-2">
      <v-icon large> mdi-book-open-variant </v-icon>
      <v-list-item-title class="pa-2">{{
        $t("app-bar.project")
      }}</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <DocDrawerItem :groups="groups"/>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>



<script>
import DocDrawerItem from "./DocDrawerItem";

//IDEA DI METTERE DELLE ICONE TUTTE A LIBRO CON I VARI SIMBOLI
export default {
  components: {
    DocDrawerItem,
  },
  data() {
    return {
      drawer: true,
      groups: [    
        {
          id: 0,
          title: "about.workflow.title",
          icon: "mdi-map-marker-path",
          to: "/about/docs/workflow",
          children: [
            {
              id: 'survey-transcription-section',    
              title: 'about.workflow.section-1.title',
              icon: "mdi-account-search-outline",
            },
            {
              id: 'data-model-section',    
              title: 'about.workflow.section-2.title',
              icon: "mdi-hexagon-multiple-outline",
            },
            {
              id: 'markup-metadata-processing-section',    
              title: 'about.workflow.section-3.title',
              icon: "mdi-file-code-outline",
            },
            {
              id: 'site-design-section',    
              title: 'about.workflow.section-4.title',
              icon: "mdi-head-lightbulb-outline",
            },
            {
              id: 'documentation-section',    
              title: 'about.workflow.section-5.title',
              icon: "mdi-file-document-edit-outline",
            },
          ],
        },
        {
          id: 1,
          title: "about.principles.title",
          icon: "mdi-book",
          to: "/about/docs/principles",
          children: [
            {
              id: "plan-section",
              title: "about.principles.section-1.title",
              icon: "mdi-file-tree-outline",
            },
            {
              id: 'treatment-section',    
              title: 'about.principles.section-2.title',
              icon: "mdi-text-shadow",
            },
          ],
        },
        { 
          id: 2,
          title: "about.models.title", 
          icon: "mdi-semantic-web",
          to: "/about/docs/models",
          children: [
            {
              id: 'rdf-section',    
              title: 'about.models.section-1.title',
              icon: "mdi-transit-connection-horizontal",
            },
            {
              id: 'frbr-section',    
              title: 'about.models.section-2.title',
              icon: "mdi-book-open-page-variant-outline",
            },
            {
              id: 'voc-section',
              title: 'about.models.section-3.title',
              icon: "mdi-bookmark-multiple-outline",
            },
            {
              id: 'ontology-section',
              title: 'about.models.section-4.title',
              icon: "mdi-graph-outline",
            },
            {
              id: 'document-section',
              title: 'about.models.section-5.title',
              icon: "mdi-file-document-outline",
            },
            {
              id: 'metadata-section',
              title: 'about.models.section-6.title',
              icon: "mdi-tag-multiple-outline",
            },
          ],
        },
        {
          id: 3,
          title: "about.processing.title",
          icon: "mdi-file-document-edit",
          to: "/about/docs/processing",
          children: [
            {
              id: 'structure-section',    
              title: 'about.processing.section-1.title',
              icon: "mdi-application-cog",
            },
            {
              id: 'markup-section',    
              title: 'about.processing.section-2.title',
              icon: "mdi-pencil-outline",
            },
            {
              id: 'addmetadata-section',    
              title: 'about.processing.section-3.title',
              icon: "mdi-database-plus-outline",
            },
            {
              id: 'scripts-section',
              title: 'about.processing.section-4.title',
              icon: "mdi-code-tags-check"
            }
          ]
        },
        {
          id: 4,
          title: "about.site.title",
          icon: "mdi-monitor-dashboard",
          to: "/about/docs/site",
          children: [
            {
              id: 'benchmark-section',    
              title: 'about.site.section-1.title',
              icon: "mdi-chart-box-outline",
            },
            {
              id: 'architecture-section',    
              title: 'about.site.section-2.title',
              icon: "mdi-sitemap",
            },
            {
              id: 'realization-section',    
              title: 'about.site.section-3.title',
              icon: "mdi-web",
            },
          ]
        },
        {
          id: 5,
          title: "about.results.title",
          icon: "mdi-export",
          to: "/about/docs/results",
          children: [
            {
              id: 'corpus-section',
              title: 'about.results.section-1.title',
              icon: "mdi-library-shelves",
            },
            {
              id: 'codes-section',
              title: 'about.results.section-2.title',
              icon: "mdi-code-braces",
            },
            {
              id: 'reports-section',
              title: 'about.results.section-3.title',
              icon: "mdi-file-chart-outline",
            },
            {
              id: 'others-section',
              title: 'about.results.section-4.title',
              icon: "mdi-folder-download-outline",
            },
          ]
        }
      ],
      mini: true,
    };
  },  
};
</script>

<style lang="scss">
//z-index forced to 0 to overlap the footer.
#document-drawer {
  z-index: 0 !important;
}
</style>