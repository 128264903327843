<template>
      <div>
        <v-alert
          :value="info"
          type="info"
          text
          outlined
          elevation="2"
          :prominent="$vuetify.breakpoint.mdAndUp"
          :dense="$vuetify.breakpoint.mdAndDown"
          border="left"          
          class="ma-2 text-body-2 text-md-body-1"
          transition="scale-transition"
        >
          <v-row align="center">
            <v-col class="grow">
              <span v-html="$t('alerts.info')"></span>
              <router-link :to="$t('alerts.editionLink')">{{$t('alerts.goTo')}}</router-link>              
            </v-col>
            <v-col class="shrink text-body-2 text-md-subtitle-1">
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="info" text @click="info = !info">{{$t('basics.close')}}</v-btn>
              <v-btn v-else icon @click="info = !info" color="info" ><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <base-card :items="sections"> </base-card>
      </div>      
</template>  

<script>
export default {
  data: () => ({         
    info: true,
    sections: [
      {
        title: "collection.browse.indexes.title",        
        text: `collection.browse.indexes.text`,
        subtext: "collection.browse.indexes.subtext",
        to: "/edition/browse/indexes",
        img: "/edition/indices.jpeg",
        imgTitle: "collection.browse.indexes.img-title"
      },
      {
        title: "collection.browse.editorialPlan.title",
        text: "collection.browse.editorialPlan.text",
        subtext: "collection.browse.editorialPlan.subtext",
        to: "/edition/browse/editorial_plan",
        img: "/edition/editorial_plan.png",
        imgTitle: "collection.browse.editorialPlan.img-title"
      },
      {
        title: "collection.browse.advanceSearch.title",
        text: "collection.browse.advanceSearch.text",
        subtext: "collection.browse.advanceSearch.subtext",
        to: "/edition/browse/advance_search",
        img: "/edition/advance_search.png",
        imgTitle: "collection.browse.advanceSearch.img-title"
      },
    ],
  }),  
};
</script>

