<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 0.2,
    }"
    min-height="200"
    transition="fade-transition"
  >
        <!-- :to="link" -->
      <v-card
        class="ma-2"
      >
        <div class="d-flex align-center">
          <v-card-title class="text-break">{{ work.title }}</v-card-title>
          <!-- <v-btn :to="link" class="ml-auto mr-4" color="primary" small>
            Visualizza
        </v-btn> -->
          <v-btn
            @click="link(work.ident)"
            class="ml-auto mr-4"
            color="primary"
            small
          >
            {{ $t('basics.goToWork') }}
          </v-btn>
        </div>
        <v-card-subtitle>
          <strong> Sezione:</strong>
          <span class="mx-1"> {{ work.section }} </span>
          <strong> Volume:</strong>
          <span class="mx-1"> {{ work.volume }} </span>
          <strong> Tomo:</strong>
          <span class="mx-1">
            {{ work.tome.replace("0", "-") }}
          </span>
          <strong> Numero documento:</strong>
          <span class="mx-1">
            {{ work.documentNumber }}
          </span>
        </v-card-subtitle>
        <v-card-text>
          <strong> Abstract: </strong>
          <v-sheet class="overflow" max-height="75">
            <p>{{ work.abstract }}</p>
          </v-sheet>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text class="d-flex">
          <div class="mr-2">
            <strong> Tipologia: </strong>
            <span v-for="doctype in work.doctypeList" :key="doctype.uri">
              {{ $t(createMetaString("doctype", doctype.id)) }}
            </span>
          </div>
          <div class="mr-2">
            <strong> Tematica: </strong>
            <span v-for="doctopic in work.doctopicList" :key="doctopic.uri">
              {{ $t(createMetaString("doctopic", doctopic.id)) }}
            </span>
          </div>
          <div class="mr-2">
            <strong> Ruolo: </strong>
            <span v-for="role in work.roleList" :key="role.uri">
              {{ $t(createMetaString("role", role.id)) }}
            </span>
          </div>
        </v-card-text>
      </v-card>
  </v-lazy>
</template>

<script>
export default {
  props: ["work"],
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    async link(ident) {
      // save new browsing position
      await this.$store.dispatch("changeBrowisingState", this.$route.path);
      return this.$router.push(
        `/edition/browse/work/${ident.replace(/_/g, "")}`
      ); //_${this.work._id}
    },
    createMetaString(meta, value) {
      return `collection.advance_search.${meta}.list.${value}`; //.replace(".","_")
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow {
  overflow: auto;
}
</style>