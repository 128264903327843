<template>
  <v-simple-table class="px-7">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('basics.tableName') }}
          </th>
          <th class="text-left">
            {{ $t('basics.tableDescription') }}
          </th>
          <th class="text-left">
            {{ $t('basics.tableId') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.name"
        >
          <td class="py-2">
            <em>
              {{ $t(item.name) }}
            </em>
          </td>
          <td class="py-2">{{ $t(item.desc) }}</td>
          <td class="py-2"><a :href="'http://doi.org/' + $t(item.id)">{{ $t(item.id) }}</a></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => [] 
      },
    }
  }
</script>