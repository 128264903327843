<template>
  <!--<div>-->
    <v-list-group :group="group.to" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>{{ group.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title  @click.prevent="$emit('route-doc', group.id)">{{
          $t(group.title)
        }}</v-list-item-title>
      </template>
        <v-list-item
        v-for="child in group.children"
        :key="child.id"        
        @click="routeGroupChildren(group.to, child.id)"
        :class="{'v-list-item--active': $route.hash.includes(child.id)}"
        link
      >
        <v-list-item-title v-text="$t(child.title)"></v-list-item-title>

        <v-list-item-icon>
          <v-icon v-text="child.icon"></v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-group>
  <!-- </div> -->
</template>

<script>
export default {
  props: ["group"],
  emit: ["route-indexes"],  
  methods: {    
    // add offset on push
    routeGroupChildren(headLink, id) {
      //if (this.$route.hash.includes(id)) return null      
      if (this.$route.path.includes(headLink)) {
        return this.$vuetify.goTo('#' + id, {offest: 10})
        //return this.$router.replace(`#${id}`);
      } else {
          return this.$router.push(headLink + "#" + id);
      }
    },
  }
};
</script>