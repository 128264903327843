export default {
    namespaced: true,
    state: {
        sortItems: [
            {
                id: 'name',
                title: ['Nome','Riferimento'],
                value: ['LABEL','PREFLABEL'],
                sort: 0,
                class: ''
            },
            // {
            //     id: 'bib-ref',
            //     title: 'Riferimento',
            //     value: ['LABEL'],
            //     sort: 0,
            //     class: 'ml-6'
            // },
            {
                id: 'occurrences',                
                title: ['Occorrenze'],
                value: ['MENTIONSCOUNTER'],
                sort: 0,
                class: 'mr-6'
            }
        ]
    },
    getters: {
        sortItems({ sortItems }){
            return sortItems;        
        }
    },
    actions: {
        updateSort(context,payload){            
            return context.commit('changeSort',payload)
        }
    },
    mutations: {
        changeSort(state,sortId){
            return state.sortItems.map(item => {
                if(item.id !== sortId) return item.sort = 0
                return item.sort === 2 ? item.sort = 0 : item.sort += 1
            })            
        }
    }
}