export default {
    state: {
        localFilters: {
            title: '',
            keywords: []
        },
        queryFilters: {
            docstatus: [],
            path: [],
            doctypeList: [],
            doctopicList: [],
            roleList: [],
            date: [1930, 1978],
            selAllDate: true
        },
    },
    getters: {
        localFilters(state){
            return state.localFilters;
        },
        queryFilters(state){
            return state.queryFilters;
        }
    },
    actions: {
        removeFilter(context,removedFilter){   
            if(removedFilter.category === "title") context.getters['localFilters'].title = ''
            else{
                if(removedFilter.category === "date") {
                    context.getters['queryFilters'].selAllDate = true
                }else{
                    context.commit('removeFilter', removedFilter)                
                }                         
            }            
        },
        removeAllFilter(context){
            context.commit('removeAllFilter');
        }
    },
    mutations: {
        removeFilter(state,removedFilter){     

            for(let selectedFilter in state.queryFilters){                    
                if(selectedFilter !== "selAllDate") state.queryFilters[selectedFilter] = state.queryFilters[selectedFilter].filter(filter => {
                    let filterValue = filter.id ? filter.id : filter
                    return filterValue != removedFilter.value
                })                
            }

            //Remove single chips for Keywords
            for(let selectedFilter in state.localFilters){                    
                if(selectedFilter !== "title") state.localFilters[selectedFilter] = state.localFilters[selectedFilter].filter(filter => {
                    let filterValue = filter
                    return filterValue != removedFilter.value
                })                
            }
        },

        removeAllFilter(state){
            state.queryFilters = {
                docstatus: [],
                path: [],
                doctypeList: [],
                doctopicList: [],
                roleList: [],
                date: [1930, 1978],
                selAllDate: true,
                
            },
            state.localFilters = {
                title: '',
                keywords: []
            }
        }            
        }    
}