export default {
    // returns sections in sections array
    getSections(state) {        
        return state.sections
    },
    // returns volumes in volumes array
    getVolumes(state) {
        return state.volumes        
    },
    // returns tomes in tomes array
    getTome(state) {
        return state.tomes        
    },
    // get stepperPath array
    getStepperPath(state) {
        return state.stepperPath
    },
    // get path array
    getPath(state,getters) {
        //if (state.stepperPath.length == 0) return '';
        let path = ''
        let stepperPath = getters.getStepperPath
        console.log(stepperPath);
        if (stepperPath[0]) {
            let section = `Sezione ${stepperPath[0].split('-')[1]} `
            path += section;
        }
        if (stepperPath[1]) {
            let volume = `Volume ${stepperPath[1].split('-')[1]} `
            path += volume
        }
        if (stepperPath[2]) {
            let tome = `Tomo ${stepperPath[2].split('-')[1]}`
            path += tome;
        }

        console.log(path);
        return path
    }
}