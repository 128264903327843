<template>
  <section>
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/edition/home.jpeg')"
      :title="$t('homepage.img-title')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title="homepage.title"
          />

          <base-heading size="text-h2" title="homepage.subtitle" weight="medium" />

          <base-body>
            {{$t('homepage.desc')}}
          </base-body>

          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <base-btn :to="'/edition'"> {{ $t('homepage.explore_btn')}} </base-btn>

            <!-- <span class="font-weight-bold ml-6 mr-4 my-4">o</span>

            <base-btn
              :to="'/about'"
              :ripple="false"
              color="white"
              class="pa-1"
              height="auto"
              text
            >
              Approfondisci il progetto
            </base-btn> -->
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: "Homepage",
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>