<template>
  <v-navigation-drawer id="edition-drawer" class="hidden-sm-and-down" :mini-variant.sync="mini" app floating clipped permanent width="280">
    <!-- <v-system-bar height="10"></v-system-bar> -->

    <v-list-item class="px-2">
      <v-icon large> mdi-book-open-variant </v-icon>
      <v-list-item-title class="pa-2">{{ $t("drawer-menu.title") }}</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <DrawerItem :items="items" :groups="groups" />
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>



<script>
import DrawerItem from "./DrawerItem";

//IDEA DI METTERE DELLE ICONE TUTTE A LIBRO CON I VARI SIMBOLI
export default {
  components: {
    DrawerItem,
  },
  data() {
    return {
      drawer: true,
      items: [
        { title: "drawer-menu.ed_plan", 
          icon: "mdi-book-multiple",
          to: "/edition/browse/editorial_plan"
          },
        { title: "drawer-menu.advance_search", 
          icon: "mdi-magnify",
          to: "/edition/browse/advance_search"
          },
      ],
      groups: [
        {
          id: 0,
          title: "drawer-menu.indexes.title",
          icon: "mdi-information-variant",
          to: "/edition/browse/indexes",
          children: [
            {
              id: "people",
              title: "drawer-menu.indexes.people",
              icon: "mdi-account",
              to: "/edition/browse/indexes/people",
            },
            {
              id: "places",
              title: "drawer-menu.indexes.places",
              icon: "mdi-map-marker",
              to: "/edition/browse/indexes/places",
            },
            {
              id: "org",
              title: "drawer-menu.indexes.org",
              icon: "mdi-office-building",
              to: "/edition/browse/indexes/organizations",
            },
            {
              id: "bib",
              title: "drawer-menu.indexes.bib",
              icon: "mdi-bookmark-multiple",
              to: "/edition/browse/indexes/bibliography",
            },
          ],
        },
      ],
      mini: true,
    };
  },
};
</script>

<style lang="scss">
//z-index forced to 0 to overlap the footer.
#edition-drawer{
  z-index: 0 !important;
}
  
</style>