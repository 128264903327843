export default {
    namespaced: true,
    state: {
        choosenPath: {},
        descriptions: [
            {
                id: '110',
                title: 'collection.editorialPlan.section-1.vol-1.title',
                citation: 'collection.editorialPlan.section-1.vol-1.citation',
                introductionSubtitle: 'collection.editorialPlan.section-1.vol-1.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-1.vol-1.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-1.vol-1.introduction',
                essay: 'collection.editorialPlan.section-1.vol-1.critic-essay'
            },
			{
                id: '121',
                title: 'collection.editorialPlan.section-1.vol-2.tome.tome-1.title',
                citation: 'collection.editorialPlan.section-1.vol-2.tome.tome-1.citation',
                introductionSubtitle: 'collection.editorialPlan.section-1.vol-2.tome.tome-1.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-1.vol-2.tome.tome-1.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-1.vol-2.tome.tome-1.introduction',
                essay: 'collection.editorialPlan.section-1.vol-2.tome.tome-1.critic-essay'
            },
			{
                id: '122',
                title: 'collection.editorialPlan.section-1.vol-2.tome.tome-2.title',
                citation: 'collection.editorialPlan.section-1.vol-2.tome.tome-2.citation',
                introductionSubtitle: 'collection.editorialPlan.section-1.vol-2.tome.tome-2.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-1.vol-2.tome.tome-2.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-1.vol-2.tome.tome-2.introduction',
                essay: 'collection.editorialPlan.section-1.vol-2.tome.tome-2.critic-essay'
            },
			{
                id: '131',
                title: 'collection.editorialPlan.section-1.vol-3.tome.tome-1.title',
                citation: 'collection.editorialPlan.section-1.vol-3.tome.tome-1.citation',
                introductionSubtitle: 'collection.editorialPlan.section-1.vol-3.tome.tome-1.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-1.vol-3.tome.tome-1.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-1.vol-3.tome.tome-1.introduction',
                essay: 'collection.editorialPlan.section-1.vol-3.tome.tome-1.critic-essay'
            },
			{
                id: '132',
                title: 'collection.editorialPlan.section-1.vol-3.tome.tome-2.title',
                citation: 'collection.editorialPlan.section-1.vol-3.tome.tome-2.citation',
                introductionSubtitle: 'collection.editorialPlan.section-1.vol-3.tome.tome-2.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-1.vol-3.tome.tome-2.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-1.vol-3.tome.tome-2.introduction',
                essay: 'collection.editorialPlan.section-1.vol-3.tome.tome-2.critic-essay'
            },
            {
                id: '141',
                title: 'collection.editorialPlan.section-1.vol-4.tome.tome-1.title',
                citation: 'collection.editorialPlan.section-1.vol-4.tome.tome-1.citation',
                introductionSubtitle: 'collection.editorialPlan.section-1.vol-4.tome.tome-1.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-1.vol-4.tome.tome-1.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-1.vol-4.tome.tome-1.introduction',
                essay: 'collection.editorialPlan.section-1.vol-4.tome.tome-1.critic-essay'
            },
            {
                id: '142',
                title: 'collection.editorialPlan.section-1.vol-4.tome.tome-2.title',
                citation: 'collection.editorialPlan.section-1.vol-4.tome.tome-2.citation',
                introductionSubtitle: 'collection.editorialPlan.section-1.vol-4.tome.tome-2.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-1.vol-4.tome.tome-2.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-1.vol-4.tome.tome-2.introduction',
                essay: 'collection.editorialPlan.section-1.vol-4.tome.tome-2.critic-essay'
            },
            {
                id: '210',
                title: 'collection.editorialPlan.section-2.vol-1.title',
                citation: 'collection.editorialPlan.section-2.vol-1.citation',
                introductionSubtitle: 'collection.editorialPlan.section-2.vol-1.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-2.vol-1.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-2.vol-1.introduction',
                essay: 'collection.editorialPlan.section-2.vol-1.critic-essay'
            },
            {
                id: '220',
                title: 'collection.editorialPlan.section-2.vol-2.title',
                citation: 'collection.editorialPlan.section-2.vol-2.citation',
                introductionSubtitle: 'collection.editorialPlan.section-2.vol-2.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-2.vol-2.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-2.vol-2.introduction',
                essay: 'collection.editorialPlan.section-2.vol-2.critic-essay'
            },
			{
                id: '230',
                title: 'collection.editorialPlan.section-2.vol-3.title',
                citation: 'collection.editorialPlan.section-2.vol-3.citation',
                introductionSubtitle: 'collection.editorialPlan.section-2.vol-3.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-2.vol-3.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-2.vol-3.introduction',
                essay: 'collection.editorialPlan.section-2.vol-3.critic-essay'
            },
			{
                id: '240',
                title: 'collection.editorialPlan.section-2.vol-4.title',
                citation: 'collection.editorialPlan.section-2.vol-4.citation',
                introductionSubtitle: 'collection.editorialPlan.section-2.vol-4.introductionSubtitle',
                criticEssaySubtitle: 'collection.editorialPlan.section-2.vol-4.critic-essaySubtitle',
                introduction: 'collection.editorialPlan.section-2.vol-4.introduction',
                essay: 'collection.editorialPlan.section-2.vol-4.critic-essay'
            }
        ]         
       },   
    mutations:{
        changePath(state,newPath){
            return state.choosenPath = newPath
        }
    },
    actions:{
        changePath(context,path){
            const choosenPath = context.state.descriptions.filter(desc => desc.id === path)[0] //? context.state.descriptions.filter(desc => desc.id === path)[0] : {};

            context.commit('changePath',choosenPath)
        }
    },
    getters: {
        choosenPath(state){
            return state.choosenPath
        }
    }
}