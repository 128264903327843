<template>
    <v-container
    class="section">
        <v-card>
            <v-card-title>{{ $t(title) }}</v-card-title>
            <v-card-text v-html="$t(text)"></v-card-text>
        </v-card>
    </v-container>
</template>


<script>
export default {
    props: ['title', 'text'],
}
</script>
