<template>
    <v-container
    >
        <v-row
        justify="center"
        align="center"
        >
            <v-img
            class="px-7"
            contain
            max-height="400"
            width="auto"
            :src="require('@/assets/about/' + $t(imageName) + '')"
            :alt="$t(altText)"
            >
            </v-img>

            <div class="text-caption px-7 pt-5">
                <em>
                    {{ $t(captionText) }}
                </em>
            </div>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: ['imageName', 'altText', 'captionText']
}
</script>

