import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
    namespaced: true,
    state: {     
        work: {},
        categoriesStructure: [
            {
              id: "people",
              title: "collection.work.tabs.text.categories.people",
              icon: "mdi-account",
              color: "#ffd600",
              activeClass: "people-active",
              show: 'prefLabel',
              entities: [],
            },
            {
              id: "places",
              title: "collection.work.tabs.text.categories.places",
              icon: "mdi-map-marker",
              color: "#81c784",
              activeClass: "places-active",
              show: 'label',
              entities: [],
            },
            {
              id: "organizations",
              title: "collection.work.tabs.text.categories.organizations",
              icon: "mdi-domain",
              color: "#e57373",
              activeClass: "organizations-active",
              show: 'label',
              entities: [],
            },            
            {
              id: "quotes",
              title: "collection.work.tabs.text.categories.quote",
              icon: "mdi-format-quote-close",
              color: "#ffa000",
              activeClass: "quote-active",
              show: 'label',
              entities: [],
            },
            {
              id: "expressions",
              title: "collection.work.tabs.text.categories.bibref",
              icon: "mdi-bookmark",
              color: "#8E24AA",
              activeClass: "bibref-active",
              show: 'label',
              entities: [],
            }
          ],        
    },
    mutations,
    actions,
    getters
}