<template>
  <div v-if="!!breadcrumbs">
    <v-breadcrumbs class="white--text" :items="breadcrumbs">
        <template v-slot:item="{item}">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"> 
              {{ $t(item.text) }}
          </v-breadcrumbs-item>                      
        </template>
       </v-breadcrumbs>
    <v-divider></v-divider>
  </div>
</template>


<script>
export default {
  props: ['breadcrumbs'],
  // computed: {
  //   breadcrumbs() {
  //     if (!this.$route.meta.breadcrumbs) return;

  //     //Disable last breadcrumb
  //     let breadcrumbs = this.$route.meta.breadcrumbs;

  //     if(Object.keys(breadcrumbs).length > 1 && breadcrumbs.people){
  //       let matched;
  //       for(const breadcrumb in  breadcrumbs){
  //           if(this.$router.currentRoute.path.match(breadcrumb)){
  //             matched = breadcrumb;              
  //             break
  //           }                                  
  //       }
  //       breadcrumbs = breadcrumbs[matched];
  //     }

  //     breadcrumbs.map((bc) => (bc.disabled = false));
  //     breadcrumbs[breadcrumbs.length - 1].disabled = true;

  //     return breadcrumbs;
  //   },
  // },
};
</script>

<style lang="scss">
  .v-breadcrumbs__item:not([class*="v-breadcrumbs__item--disabled"]) {
    color: white !important;
  }
</style>
