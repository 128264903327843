import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
    namespaced: true,
    state: {
        stepperPath: [],
        tomes: [],
        volumes: [],
        sections: [
            {
                id: "section-1",
                editionID: "collection.editorialPlan.section-1.id",
                title: "collection.editorialPlan.section-1.title",
                subTitle: "collection.editorialPlan.section-1.sub-title",
                img: "s1.jpeg",
                imgTitle: "collection.editorialPlan.section-1.img-title",
                showDetails: false,
                volumes: [
                    {
                        id: "volume-1",
                        editionID: "collection.editorialPlan.section-1.vol-1.id",
                        title: "collection.editorialPlan.section-1.vol-1.title",
                        subTitle: "collection.editorialPlan.section-1.vol-1.sub-title",
                        desc: "collection.editorialPlan.section-1.vol-1.desc",
                        img: "s1v1.jpeg",
                        imgTitle: "collection.editorialPlan.section-1.vol-1.img-title",
                        showDetails: false,                        
                    },
                    {
                        id: "volume-2",
                        editionID: "collection.editorialPlan.section-1.vol-2.id",
                        title: "collection.editorialPlan.section-1.vol-2.title",
                        subTitle: "collection.editorialPlan.section-1.vol-2.sub-title",
                        desc: "collection.editorialPlan.section-1.vol-2.desc",                        
                        img: "s1v2.jpeg",
                        imgTitle: "collection.editorialPlan.section-1.vol-2.img-title",
                        showDetails: false,
                        tome: [
                            {
                                id: "tome-1",
                                editionID: "collection.editorialPlan.section-1.vol-2.tome.tome-1.id",
                                title: "collection.editorialPlan.section-1.vol-2.tome.tome-1.title",
                                subTitle: "collection.editorialPlan.section-1.vol-2.tome.tome-1.sub-title",                                
                                img: "s1v2t1.jpeg",
                                imgTitle: "collection.editorialPlan.section-1.vol-2.tome.tome-1.img-title",
                                showDetails: false
                            },
                            {
                                id: "tome-2",
                                editionID: "collection.editorialPlan.section-1.vol-2.tome.tome-2.id",
                                title: "collection.editorialPlan.section-1.vol-2.tome.tome-2.title",
                                subTitle: "collection.editorialPlan.section-1.vol-2.tome.tome-2.sub-title",
                                img: "s1v2t2.jpeg",
                                imgTitle: "collection.editorialPlan.section-1.vol-2.tome.tome-2.img-title",
                                showDetails: false
                            }
                        ]
                    },
                    {
                        id: "volume-3",
                        editionID: "collection.editorialPlan.section-1.vol-3.id",
                        title: "collection.editorialPlan.section-1.vol-3.title",
                        subTitle: "collection.editorialPlan.section-1.vol-3.sub-title",
                        desc: "collection.editorialPlan.section-1.vol-3.desc",
                        img: "s1v3.jpeg",
                        imgTitle: "collection.editorialPlan.section-1.vol-3.img-title",
                        showDetails: false,
                        tome: [
                            {
                                id: "tome-1",
                                editionID: "collection.editorialPlan.section-1.vol-3.tome.tome-1.id",
                                title: "collection.editorialPlan.section-1.vol-3.tome.tome-1.title",
                                subTitle: "collection.editorialPlan.section-1.vol-3.tome.tome-1.sub-title",                                
                                img: "s1v3t1.jpg",
                                imgTitle: "collection.editorialPlan.section-1.vol-3.tome.tome-1.img-title",
                                showDetails: false
                            },
                            {
                                id: "tome-2",
                                editionID: "collection.editorialPlan.section-1.vol-3.tome.tome-2.id",
                                title: "collection.editorialPlan.section-1.vol-3.tome.tome-2.title",
                                subTitle: "collection.editorialPlan.section-1.vol-3.tome.tome-2.sub-title",
                                img: "s1v3t2.jpg",
                                imgTitle: "collection.editorialPlan.section-1.vol-3.tome.tome-2.img-title",
                                showDetails: false
                            }
                        ]
                    },
                    {
                        id: "volume-4",
                        editionID: "collection.editorialPlan.section-1.vol-4.id",
                        title: "collection.editorialPlan.section-1.vol-4.title",
                        subTitle: "collection.editorialPlan.section-1.vol-4.sub-title",
                        desc: "collection.editorialPlan.section-1.vol-4.desc",
                        img: "s1v4.jpg",
                        imgTitle: "collection.editorialPlan.section-1.vol-4.img-title",
                        showDetails: false,
                        tome: [
                            {
                                id: "tome-1",
                                editionID: "collection.editorialPlan.section-1.vol-4.tome.tome-1.id",
                                title: "collection.editorialPlan.section-1.vol-4.tome.tome-1.title",
                                subTitle: "collection.editorialPlan.section-1.vol-4.tome.tome-1.sub-title",
                                img: "s1v4t1.jpeg",
                                imgTitle: "collection.editorialPlan.section-1.vol-4.tome.tome-1.img-title",
                                showDetails: false
                            },
                            {
                                id: "tome-2",
                                editionID: "collection.editorialPlan.section-1.vol-3.tome.tome-2.id",
                                title: "collection.editorialPlan.section-1.vol-4.tome.tome-2.title",
                                subTitle: "collection.editorialPlan.section-1.vol-4.tome.tome-2.sub-title",
                                img: "s1v4t2.jpeg",
                                imgTitle: "collection.editorialPlan.section-1.vol-4.tome.tome-2.img-title",
                                showDetails: false
                            }
                        ]
                    }
                ]
            },
            {
                id: "section-2",
                editionID: "collection.editorialPlan.section-2.id",
                title: "collection.editorialPlan.section-2.title",
                subTitle: "collection.editorialPlan.section-2.sub-title",
                img: "s2.jpeg",
                imgTitle: "collection.editorialPlan.section-1.img-title",
                showDetails: false,
                volumes: [
                    {
                        id: "volume-1",
                        editionID: "collection.editorialPlan.section-2.vol-1.id",
                        title: "collection.editorialPlan.section-2.vol-1.title",
                        subTitle: "collection.editorialPlan.section-2.vol-1.sub-title",
                        desc: "collection.editorialPlan.section-2.vol-1.desc",
                        img: "s2v1.jpg",
                        imgTitle: "collection.editorialPlan.section-2.vol-1.img-title",
                        showDetails: false
                    },
                    {
                        id: "volume-2",
                        editionID: "collection.editorialPlan.section-2.vol-2.id",
                        title: "collection.editorialPlan.section-2.vol-2.title",
                        subTitle: "collection.editorialPlan.section-2.vol-2.sub-title",
                        desc: "collection.editorialPlan.section-2.vol-2.desc",
                        img: "s2v2.jpeg",
                        imgTitle: "collection.editorialPlan.section-2.vol-2.img-title",
                        showDetails: false                 
                    },
                    {
                        id: "volume-3",
                        editionID: "collection.editorialPlan.section-2.vol-3.id",
                        title: "collection.editorialPlan.section-2.vol-3.title",
                        subTitle: "collection.editorialPlan.section-2.vol-3.sub-title",
                        desc: "collection.editorialPlan.section-2.vol-3.desc",                        
                        img: "s2v3.jpeg",
                        imgTitle: "collection.editorialPlan.section-2.vol-3.img-title",
                        showDetails: false
                    },
                    {
                        id: "volume-4",
                        editionID: "collection.editorialPlan.section-2.vol-4.id",
                        title: "collection.editorialPlan.section-2.vol-4.title",
                        subTitle: "collection.editorialPlan.section-2.vol-4.sub-title",
                        desc: "collection.editorialPlan.section-2.vol-4.desc",
                        img: "s2v4.jpeg",
                        imgTitle: "collection.editorialPlan.section-2.vol-4.img-title",
                        showDetails: false                    
                    }
                ]
            }            
        ]
    },
    mutations,
    actions,
    getters
}