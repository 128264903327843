<template>
    <!--:indeterminate="isLoading"-->
  <v-progress-linear
    :active="isLoading"
    indeterminate
    absolute
    bottom
    color="primary accent-4"
  ></v-progress-linear>
</template>

<script>
export default {
    props: ['isLoading']
}
</script>

