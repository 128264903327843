<template>
<v-footer
    id="footer"
    app
    padless
    absolute
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
      v-for="link in links"
      :key="link.key"
      class="lighten-4 text-center"
      >
        <v-btn
        v-if="link.url"
        :to="link.url"
        color="primary"
        plain
        class="my-2"
        text
        >
        {{ $t(link.label) }}
        </v-btn>
        <!-- <v-btn
        v-if="link.mailto"
        :href="link.mailto"
        color="primary"
        plain
        class="my-2"
        text
        >
        {{ $t(link.label) }}
        </v-btn> -->
      </v-col>
      <v-col
      class="secondary text-subtitle-1 lighten-4 primary--text text-center"
      cols="12"
      >
        <v-container
        v-html="$t(citation)">
        </v-container>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      links: [
        { 
          key: 1,
          label: 'contacts.title',
          url: '/contacts'
          //mailto: 'mailto:aldomoro@unibo.it'
        },
        {
          key: 2,
          label: 'credits.title',
          url: '/credits'
        },
        { 
          key: 3,
          label: 'terms.title',
          url: '/terms'
        },
      ],
      citation: 'app-bar.citation'
    }),
  }
</script>
