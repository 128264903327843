export default {
    getWork(state){
        return state.work
    },
    getText(state){
        return state.work.text
    }, 
    getNavigation(state){        
        return {succ: state.work.succ, prev: state.work.prev}
    },
    getMetadata(state){
        return state.work.metadata
    },    
    getIdent(state){
        return state.work.metadata.ident
    },
    getTitle(state){
        return state.work.metadata.title
    },
    getCategories(state){
        return state.work.categories
    },    
    getCategoriesStructure(state){
        return state.categoriesStructure
    },
    getFootnotes(state){
        return state.work.footnotes
    },
    getChapters(state){
        if(state.work.chapters) return state.work.chapters
        return null
    }
}