<template>
<v-fab-transition>
    <v-btn
    v-scroll="onScroll"
    v-show="fab"
    @click="toTop"   
    :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''"
    color="primary"
    fixed
    elevation="3"
    fab
    large
    bottom
    right
    ><v-icon>mdi-chevron-up</v-icon></v-btn
  >
</v-fab-transition> 
</template>

<script>
export default {
  data() {
    return {
      fab: false,
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 200;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
