<template>
  <v-overlay
    :absolute="absolute"
    :color="color"
    :opacity="opacity"
    :z-index="zIndex"    
  >
    <v-progress-circular
      :size="progressSize"
      :width="progressWidth"
      :color="progressColor"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
    props: {        
        absolute: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: 'secondaryLighten'
        },
        opacity: Number,
        zIndex: {
            type: Number,
            default: 2
        },        
        progressSize: Number,
        progressWidth: Number,
        progressColor: {
            type: String,
            default: 'primary'
        }
    }
}
</script>
