<template>
    <base-card :items="sections" :md="12" :sm="12" :lg="12" :xl="12" :height="3" > </base-card>
</template>  

<script>
export default {
  data: () => ({         
    sections: [
      {
        title: "about.workflow.title",
        subtext: "about.workflow.subtitle",
        to: "/about/docs/workflow",
        img: "/about/about-workflow.png",
      },
      {
        title: "about.principles.title",
        subtext: "about.principles.subtitle",
        to: "/about/docs/principles",
        img: "/about/about-principles.png",
      },
      {
        title: "about.models.title",
        subtext: "about.models.subtitle",
        to: "/about/docs/models",
        img: "/about/about-modelling.png",
      },
      {
        title: "about.processing.title",
        subtext: "about.processing.subtitle",
        to: "/about/docs/processing",
        img: "/about/about-processing.png",
      },
      {
        title: "about.site.title",
        subtext: "about.site.subtitle",
        to: "/about/docs/site",
        img: "/about/about-site.png",
      },
      {
        title: "about.results.title",
        subtext: "about.results.subtitle",
        to: "/about/docs/results",
        img: "/about/about-results.png",
      },
    ],
  })
};
</script>