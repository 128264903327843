<template>
  <div>
      <v-app-bar  color="primary" :max-height="height" :extension-height="ext_height" app clipped-left dark >  <!-- clipped-left -->
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
      <v-btn to="/" color="white">
        <div class="d-flex align-center">
          <v-img
            alt="Project Logo"
            class="shrink mr-2"
            contain
            src="@/assets/moroHead.png"
            transition="scale-transition"
            width="40"
          />
          <v-toolbar-title
            class="primary--text shrink mt-1 hidden-md-and-down"
            
            alt="Project Name"
            contain
            min-width="100"
            width="100"
          >
            {{ $t("app-bar.title") }}
          </v-toolbar-title>
        </div>
      </v-btn>
      <v-spacer></v-spacer>

      <v-toolbar-items       
          class="pr-2 hidden-sm-and-down"
        >         
        
          <v-btn
            v-for="(view, i) in views"
            :key="i"
            :to="view.to"   
            :disabled="view.disabled"
            class="font-weight-bold"
            text
            >
            <!-- active-class="app-button-active" -->
            {{ $t(view.name) }}
          </v-btn>
        </v-toolbar-items>
        <!-- <v-tabs        
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(view, i) in views"
            :key="i"
            :to="view.to"
            :ripple="false"
            class="white--text font-weight-bold"
            min-width="96"
            text            
          >
            {{ $t(view.name) }}
          </v-tab>
        </v-tabs> -->
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon            
            color="secondaryLighten"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-web</v-icon>
            {{$i18n.locale}}
          </v-btn>
        </template>

        <v-list dense>          
        <v-subheader>{{ $t("app-bar.locale") }}</v-subheader>
          <v-list-item-group
            v-model="$i18n.locale"
            color="primary">
          <v-list-item
            v-for="(locale,i) in locales"
            :key="i"
            :value="locale.code"
          >
            <v-list-item-title>{{ locale.name }}</v-list-item-title>
          </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>                       
      <template v-if="breadcrumbs" v-slot:extension>                
          <Breadcrumbs v-show="showExtension" :breadcrumbs="breadcrumbs"/>
      </template>                               
    </v-app-bar>      

    <v-navigation-drawer
      v-model="drawer"
      app            
      :temporary="temporary"
    >
    <!-- clipped -->
      <v-list dense nav>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item v-for="view in views" :key="view.name" :to="view.to" :disabled="view.disabled">
            <v-list-item-title>{{ $t(view.name) }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>    
  </div>
</template>

<script>
import Breadcrumbs from './Breadcrumbs'
import { getSupportedLocales } from "@/util/i18n/supported-locales"


export default {
  name: "NavigationBar",
  components: {
    Breadcrumbs
  },
  data() {
    return {
      drawer: null,
      group: null,
      showExtension: true,
      lastScrollPosition: 0,
      ext_height: 48,
      height: 122,
      views: [
        {
          name: 'app-bar.aldo-moro',
          to: "/aldomoro",
          disabled: true
        },
        {
          name: "app-bar.works",
          to: "/edition",
          disabled: false
        },
        {
          name: "app-bar.visualizations",
          to: "/visualizations",
          disabled: true
        },
        {
          name: "app-bar.project",
          to: "/about",
          disabled: false
        },
      ],
      locales: getSupportedLocales(),      
    };
  },
  computed: {
    // temporary (xD) workaround for the navigation drawer clipped view remaining visible on md >= screans
    temporary() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return false;
      }
    },
    // Check if route has breadcrumbs, if so pass them to the Bradcrumbs component
    breadcrumbs() {
      if (!this.$route.meta.breadcrumbs) return;      

      //Disable last breadcrumb
      let breadcrumbs = this.$route.meta.breadcrumbs;

      if(this.$route.meta.dynamic_b){
        let matched;
        for(const breadcrumb in  breadcrumbs){
            if(this.$router.currentRoute.path.match(breadcrumb)){
              matched = breadcrumb;              
              break
            }                                  
        }
        breadcrumbs = breadcrumbs[matched];
      }

      breadcrumbs.map((bc) => (bc.disabled = false));
      breadcrumbs[breadcrumbs.length - 1].disabled = true;

      return breadcrumbs;
    },
  },
  methods: {
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {        
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {         
        return;
      }                  
      this.showExtension = currentScrollPosition < this.lastScrollPosition;      
      this.lastScrollPosition = currentScrollPosition;
      if(this.showExtension){
        //Change height of ext and header - Add a transition with CSS                    
          this.ext_height = 48     
          this.height = 122  
          document.getElementsByTagName('header')[0].classList.remove('hide_ext')        
          document.getElementsByClassName('v-toolbar__extension')[0].classList.remove('hide_ext')
      }else{               
          this.ext_height = 0
          this.height = 64               
          document.getElementsByTagName('header')[0].classList.add('hide_ext')
          document.getElementsByClassName('v-toolbar__extension')[0].classList.add('hide_ext')          
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
// .app-button::after{
//   transition: 300ms;
//   height: 5px;
//   content: "";
//   background-color: white;
//   position: absolute;
//   bottom: 5px;
//   opacity: 0;
// }

// .app-button-active::before{  
//   bottom: 10px;
//   opacity: 1;
// }

header{
  transition: height 0.15s ease-out;  
}

header.hide_ext {
  transition: height 0.25s ease-in;
}

header .v-toolbar__extension{  
  transition: height 0.15s ease-out;  
}

header .v-toolbar__extension.hide_ext {
  height: 0px !important;
  transition: height 0.25s ease-in;
}

</style>


<!--##### LANGUAGE SELECTOR #####-->
<!--
<div class="locale-switcher">
    <select v-model="$i18n.locale">
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.name}}
      </option>
    </select>



    data: () => ({
    
  })
</div>-->