export default {
    // params.id params.step
    async pushStep(context,params){        
        context.commit('changeStepperPath', params);        
    },
    // change selected volumes array
    async changeVolumes(context){
        const stepperPath = context.getters.getStepperPath;
        const sections = context.getters.getSections;

        const section = stepperPath[0];        

        const volumes = sections.filter(s => s.id === section)[0].volumes
        //push new selected volumes inside volumes array
        context.commit('pushVolumes',{volumes: volumes})
    },
    // change selected tomes array
    async changeTomes(context){
        const stepperPath = context.getters.getStepperPath;                

        if(context.getters.getVolumes.length == 0) context.dispatch('changeVolumes');            
        let volumes = context.getters.getVolumes
        
        
        const volume = stepperPath[1];        

        const tomes = volumes.filter(v => v.id === volume)[0].tome
        // push selected tomes inside volumes array
        context.commit('pushTomes',{tomes: tomes})
    },
    // clear path when it's reset based on the step user went back on
    async clearPath(context,params){
        const step = params.step;
        context.commit('clearPath',step)
    }
}