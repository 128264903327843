<template>
  <section>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <navigation-drawer>      
    </navigation-drawer>    
    <v-container id="container" > <!--fluid-->
      <v-row>
        <v-col class="col">    
          <router-view></router-view>          
        </v-col>
      </v-row>
    </v-container>
    </div>    
    <div v-else>          
    <v-container id="container" > <!--fluid-->
      <v-row>
        <v-col class="col">    
          <router-view></router-view>          
        </v-col>
      </v-row>
    </v-container>
    <!-- <div style="height:56px; width:100%;">

    </div> -->
    <bottom-navigation-bar :items="bottomNavigationItems" >
    </bottom-navigation-bar>
    </div>    
  </section>
</template>

<script>
export default {
  data() {
    return {
      bottomNavigationItems: [
        {
          title: "drawer-menu.indexes.title",
          icon: "mdi-information-variant",
          to: "/edition/browse/indexes",
        },
        {
          title: "drawer-menu.ed_plan",
          icon: "mdi-book-multiple",
          to: "/edition/browse/editorial_plan/",
        },
        {
          title: "drawer-menu.advance_search",
          icon: "mdi-magnify",
          to: "/edition/browse/advance_search",
        },
      ]      
    }
  }
}
</script>

<style lang="scss">
  
</style>