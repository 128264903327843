<template>
    <v-container>
        <base-section-heading
        title="terms.title">
        </base-section-heading>
        <v-card
        class="mt-5 mb-5"
        v-for="section in sections"
        :key="section.key"
        >
            <v-card-title
            > {{ $t(section.title) }}
            </v-card-title>
            <v-card-text>
                <div
                v-html="$t(section.text)"
                ></div>
                <v-card
                class="mt-3"
                shaped
                elevation=1
                v-for="citation in section.citations"
                :key="citation.key"
                >
                    <v-card-subtitle
                    v-html="$t(citation.subtitle)">
                    </v-card-subtitle>
                    <v-card-text
                    v-html="$t(citation.text)">
                    </v-card-text>    
                </v-card>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
          sections: [
            {
              key: 1,
              title: 'terms.section-1.title',
              text: 'terms.section-1.text'
            },
            {
              key: 2,
              title: 'terms.section-2.title',
              text: 'terms.section-2.text'
            },
            {
              key: 3,
              title: 'terms.section-3.title',
              text: 'terms.section-3.text',
              citations: [
                  {
                      key: 1,
                      subtitle: 'terms.section-3.citations.citation-1.subtitle',
                      text: 'terms.section-3.citations.citation-1.text'
                  },
                  {
                      key: 2,
                      subtitle: 'terms.section-3.citations.citation-2.subtitle',
                      text: 'terms.section-3.citations.citation-2.text'
                  },
                  {
                      key: 3,
                      subtitle: 'terms.section-3.citations.citation-3.subtitle',
                      text: 'terms.section-3.citations.citation-3.text'
                  },
                  {
                      key: 4,
                      subtitle: 'terms.section-3.citations.citation-4.subtitle',
                      text: 'terms.section-3.citations.citation-4.text'
                  },
                  {
                      key: 5,
                      subtitle: 'terms.section-3.citations.citation-5.subtitle',
                      text: 'terms.section-3.citations.citation-5.text'
                  },
                  {
                      key: 6,
                      subtitle: 'terms.section-3.citations.citation-6.subtitle',
                      text: 'terms.section-3.citations.citation-6.text'
                  },
                  {
                      key: 7,
                      subtitle: 'terms.section-3.citations.citation-7.subtitle',
                      text: 'terms.section-3.citations.citation-7.text'
                  },
                  {
                      key: 8,
                      subtitle: 'terms.section-3.citations.citation-8.subtitle',
                      text: 'terms.section-3.citations.citation-8.text'
                  }
              ]
            }
          ]
        }
    }
}

</script>

<style lang="css" scoped>
.cit {
        margin: 0 0 0 ;
    }
div >>> .highlight {
    color: #1560BD ;
    font-weight: 500 ;
    }
div >>> a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
</style>
