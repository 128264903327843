<template>
  <v-container>
    <base-section-heading 
        title="credits.title">
    </base-section-heading>
    <v-card
    v-for="section in sections"
    :key="section.key"
    >
      <v-card-title
      > {{ $t(section.title) }}
      </v-card-title>
      <v-card-text
      >
        <div
        v-html="$t(section.text)"
        ></div>
        <ul
        v-for="element in section.elements"
        :key="element.key"
        >
          <li>
              <p>
                <span class="highlight">
                  {{ $t(element.title) }}
                </span> 
                <small>
                {{ $t(element.subtitle) }}
                </small>
                <br>
                <em>
                  {{ $t(element.text) }}
                </em>
              </p>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
          sections: [
            {
              key: 1,
              title: 'credits.section-1.title',
              elements: [
                {
                  key: 1,
                  title: 'credits.section-1.elements.element-1.title',
                  subtitle: 'credits.section-1.elements.element-1.subtitle'
                },
                {
                  key: 2,
                  title: 'credits.section-1.elements.element-2.title',
                  subtitle: 'credits.section-1.elements.element-2.subtitle'
                },
                {
                  key: 3,
                  title: 'credits.section-1.elements.element-3.title',
                  subtitle: 'credits.section-1.elements.element-3.subtitle'
                },
                {
                  key: 4,
                  title: 'credits.section-1.elements.element-4.title',
                  subtitle: 'credits.section-1.elements.element-4.subtitle'
                },
                {
                  key: 5,
                  title: 'credits.section-1.elements.element-5.title',
                  subtitle: 'credits.section-1.elements.element-5.subtitle'
                },
                {
                  key: 6,
                  title: 'credits.section-1.elements.element-6.title',
                  subtitle: 'credits.section-1.elements.element-6.subtitle'
                },
                {
                  key: 7,
                  title: 'credits.section-1.elements.element-7.title',
                  subtitle: 'credits.section-1.elements.element-7.subtitle'
                },
                {
                  key: 8,
                  title: 'credits.section-1.elements.element-8.title',
                  subtitle: 'credits.section-1.elements.element-8.subtitle'
                },
                {
                  key: 9,
                  title: 'credits.section-1.elements.element-9.title',
                  subtitle: 'credits.section-1.elements.element-9.subtitle'
                },
                {
                  key: 10,
                  title: 'credits.section-1.elements.element-10.title',
                  subtitle: 'credits.section-1.elements.element-10.subtitle'
                },
                {
                  key: 11,
                  title: 'credits.section-1.elements.element-11.title',
                  subtitle: 'credits.section-1.elements.element-11.subtitle'
                },
                {
                  key: 12,
                  title: 'credits.section-1.elements.element-12.title',
                  subtitle: 'credits.section-1.elements.element-12.subtitle'
                },
                {
                  key: 13,
                  title: 'credits.section-1.elements.element-13.title',
                  subtitle: 'credits.section-1.elements.element-13.subtitle'
                },
                {
                  key: 14,
                  title: 'credits.section-1.elements.element-14.title',
                  subtitle: 'credits.section-1.elements.element-14.subtitle'
                },
                {
                  key: 15,
                  title: 'credits.section-1.elements.element-15.title',
                  subtitle: 'credits.section-1.elements.element-15.subtitle'
                },
                {
                  key: 16,
                  title: 'credits.section-1.elements.element-16.title',
                  subtitle: 'credits.section-1.elements.element-16.subtitle'
                },
                {
                  key: 17,
                  title: 'credits.section-1.elements.element-17.title',
                  subtitle: 'credits.section-1.elements.element-17.subtitle'
                },
                {
                  key: 18,
                  title: 'credits.section-1.elements.element-18.title',
                  subtitle: 'credits.section-1.elements.element-18.subtitle'
                },
                {
                  key: 19,
                  title: 'credits.section-1.elements.element-19.title',
                  subtitle: 'credits.section-1.elements.element-19.subtitle'
                },
                {
                  key: 20,
                  title: 'credits.section-1.elements.element-20.title',
                  subtitle: 'credits.section-1.elements.element-20.subtitle'
                },
                {
                  key: 21,
                  title: 'credits.section-1.elements.element-21.title',
                  subtitle: 'credits.section-1.elements.element-21.subtitle'
                },
                {
                  key: 22,
                  title: 'credits.section-1.elements.element-22.title',
                  subtitle: 'credits.section-1.elements.element-22.subtitle'
                },
                {
                  key: 23,
                  title: 'credits.section-1.elements.element-23.title',
                  subtitle: 'credits.section-1.elements.element-23.subtitle'
                },
              ]
            },
            {
              key: 2,
              title: 'credits.section-2.title',
              elements: [
                {
                  key: 1,
                  title: 'credits.section-2.elements.element-1.title',
                  subtitle: 'credits.section-2.elements.element-1.subtitle',
                  text: 'credits.section-2.elements.element-1.text'
                },
                {
                  key: 2,
                  title: 'credits.section-2.elements.element-2.title',
                  subtitle: 'credits.section-2.elements.element-2.subtitle',
                  text: 'credits.section-2.elements.element-2.text'
                },
                {
                  key: 3,
                  title: 'credits.section-2.elements.element-3.title',
                  subtitle: 'credits.section-2.elements.element-3.subtitle',
                  text: 'credits.section-2.elements.element-3.text'
                },
                {
                  key: 4,
                  title: 'credits.section-2.elements.element-4.title',
                  subtitle: 'credits.section-2.elements.element-4.subtitle',
                  text: 'credits.section-2.elements.element-4.text'
                },
                {
                  key: 5,
                  title: 'credits.section-2.elements.element-5.title',
                  subtitle: 'credits.section-2.elements.element-5.subtitle',
                  text: 'credits.section-2.elements.element-5.text'
                },
                {
                  key: 6,
                  title: 'credits.section-2.elements.element-6.title',
                  subtitle: 'credits.section-2.elements.element-6.subtitle',
                  text: 'credits.section-2.elements.element-6.text'
                },
                {
                  key: 7,
                  title: 'credits.section-2.elements.element-7.title',
                  subtitle: 'credits.section-2.elements.element-7.subtitle',
                  text: 'credits.section-2.elements.element-7.text'
                },
                {
                  key: 8,
                  title: 'credits.section-2.elements.element-8.title',
                  subtitle: 'credits.section-2.elements.element-8.subtitle',
                  text: 'credits.section-2.elements.element-8.text'
                },
                {
                  key: 9,
                  title: 'credits.section-2.elements.element-9.title',
                  subtitle: 'credits.section-2.elements.element-9.subtitle',
                  text: 'credits.section-2.elements.element-9.text'
                },
                {
                  key: 10,
                  title: 'credits.section-2.elements.element-10.title',
                  subtitle: 'credits.section-2.elements.element-10.subtitle',
                  text: 'credits.section-2.elements.element-10.text'
                },
                {
                  key: 11,
                  title: 'credits.section-2.elements.element-11.title',
                  subtitle: 'credits.section-2.elements.element-11.subtitle',
                  text: 'credits.section-2.elements.element-11.text'
                },
                {
                  key: 12,
                  title: 'credits.section-2.elements.element-12.title',
                  subtitle: 'credits.section-2.elements.element-12.subtitle',
                  text: 'credits.section-2.elements.element-12.text'
                }
              ]
            },
            {
              key: 3,
              title: 'credits.section-3.title',
              elements: [
                {
                  key: 1,
                  title: 'credits.section-3.elements.element-1.title',
                  subtitle: 'credits.section-3.elements.element-1.subtitle',
                  text: 'credits.section-3.elements.element-1.text'
                },
                {
                  key: 2,
                  title: 'credits.section-3.elements.element-2.title',
                  subtitle: 'credits.section-3.elements.element-2.subtitle',
                  text: 'credits.section-3.elements.element-2.text'
                },
                {
                  key: 3,
                  title: 'credits.section-3.elements.element-3.title',
                  subtitle: 'credits.section-3.elements.element-3.subtitle',
                  text: 'credits.section-3.elements.element-3.text'
                },
                {
                  key: 4,
                  title: 'credits.section-3.elements.element-4.title',
                  subtitle: 'credits.section-3.elements.element-4.subtitle',
                  text: 'credits.section-3.elements.element-4.text'
                },
                {
                  key: 5,
                  title: 'credits.section-3.elements.element-5.title',
                  subtitle: 'credits.section-3.elements.element-5.subtitle',
                  text: 'credits.section-3.elements.element-5.text'
                },
                {
                  key: 6,
                  title: 'credits.section-3.elements.element-6.title',
                  subtitle: 'credits.section-3.elements.element-6.subtitle',
                  text: 'credits.section-3.elements.element-6.text'
                },
                {
                  key: 7,
                  title: 'credits.section-3.elements.element-7.title',
                  subtitle: 'credits.section-3.elements.element-7.subtitle',
                  text: 'credits.section-3.elements.element-7.text'
                },
                {
                  key: 8,
                  title: 'credits.section-3.elements.element-8.title',
                  subtitle: 'credits.section-3.elements.element-8.subtitle',
                  text: 'credits.section-3.elements.element-8.text'
                },
                {
                  key: 9,
                  title: 'credits.section-3.elements.element-9.title',
                  subtitle: 'credits.section-3.elements.element-9.subtitle',
                  text: 'credits.section-3.elements.element-9.text'
                }
              ]
            },
            {
              key: 4,
              title: 'credits.section-4.title',
              elements: [
                {
                  key: 1,
                  title: 'credits.section-4.elements.element-1.title',
                  subtitle: 'credits.section-4.elements.element-1.subtitle',
                  text: 'credits.section-4.elements.element-1.text'
                },
                {
                  key: 2,
                  title: 'credits.section-4.elements.element-2.title',
                  subtitle: 'credits.section-4.elements.element-2.subtitle',
                  text: 'credits.section-4.elements.element-2.text'
                },
                {
                  key: 3,
                  title: 'credits.section-4.elements.element-3.title',
                  subtitle: 'credits.section-4.elements.element-3.subtitle',
                  text: 'credits.section-4.elements.element-3.text'
                },
                {
                  key: 4,
                  title: 'credits.section-4.elements.element-4.title',
                  subtitle: 'credits.section-4.elements.element-4.subtitle',
                  text: 'credits.section-4.elements.element-4.text'
                },
                {
                  key: 5,
                  title: 'credits.section-4.elements.element-5.title',
                  subtitle: 'credits.section-4.elements.element-5.subtitle',
                  text: 'credits.section-4.elements.element-5.text'
                },
                {
                  key: 6,
                  title: 'credits.section-4.elements.element-6.title',
                  subtitle: 'credits.section-4.elements.element-6.subtitle',
                  text: 'credits.section-4.elements.element-6.text'
                },
                {
                  key: 7,
                  title: 'credits.section-4.elements.element-7.title',
                  subtitle: 'credits.section-4.elements.element-7.subtitle',
                  text: 'credits.section-4.elements.element-7.text'
                },
                {
                  key: 8,
                  title: 'credits.section-4.elements.element-8.title',
                  subtitle: 'credits.section-4.elements.element-8.subtitle',
                  text: 'credits.section-4.elements.element-8.text'
                },
                {
                  key: 9,
                  title: 'credits.section-4.elements.element-9.title',
                  subtitle: 'credits.section-4.elements.element-9.subtitle',
                  text: 'credits.section-4.elements.element-9.text'
                },
                {
                  key: 10,
                  title: 'credits.section-4.elements.element-10.title',
                  subtitle: 'credits.section-4.elements.element-10.subtitle',
                  text: 'credits.section-4.elements.element-10.text'
                },
                {
                  key: 11,
                  title: 'credits.section-4.elements.element-11.title',
                  subtitle: 'credits.section-4.elements.element-11.subtitle',
                  text: 'credits.section-4.elements.element-11.text'
                },
                {
                  key: 12,
                  title: 'credits.section-4.elements.element-12.title',
                  subtitle: 'credits.section-4.elements.element-12.subtitle',
                  text: 'credits.section-4.elements.element-12.text'
                },
				{
                  key: 13,
                  title: 'credits.section-4.elements.element-13.title',
                  subtitle: 'credits.section-4.elements.element-13.subtitle',
                  text: 'credits.section-4.elements.element-13.text'
                },
				{
                  key: 14,
                  title: 'credits.section-4.elements.element-14.title',
                  subtitle: 'credits.section-4.elements.element-14.subtitle',
                  text: 'credits.section-4.elements.element-14.text'
                },
        {
                  key: 15,
                  title: 'credits.section-4.elements.element-15.title',
                  subtitle: 'credits.section-4.elements.element-15.subtitle',
                  text: 'credits.section-4.elements.element-15.text'
                },
        {
                  key: 16,
                  title: 'credits.section-4.elements.element-16.title',
                  subtitle: 'credits.section-4.elements.element-16.subtitle',
                  text: 'credits.section-4.elements.element-16.text'
                },
        {
                  key: 17,
                  title: 'credits.section-4.elements.element-17.title',
                  subtitle: 'credits.section-4.elements.element-17.subtitle',
                  text: 'credits.section-4.elements.element-17.text'
                },
        {
                  key: 18,
                  title: 'credits.section-4.elements.element-18.title',
                  subtitle: 'credits.section-4.elements.element-18.subtitle',
                  text: 'credits.section-4.elements.element-18.text'
                },
        {
                  key: 19,
                  title: 'credits.section-4.elements.element-19.title',
                  subtitle: 'credits.section-4.elements.element-19.subtitle',
                  text: 'credits.section-4.elements.element-19.text'
                }
              ]
            },
            {
              key: 5,
              title: 'credits.section-5.title',
              elements: [
                {
                  key: 1,
                  title: 'credits.section-5.elements.element-1.title',
                },
                {
                  key: 2,
                  title: 'credits.section-5.elements.element-2.title',
                },
                {
                  key: 3,
                  title: 'credits.section-5.elements.element-3.title',
                }
              ]
            },
            {
              key: 6,
              title: 'credits.section-6.title',
              elements: [
                {
                  key: 1,
                  title: 'credits.section-6.elements.element-1.title',
                },
                {
                  key: 2,
                  title: 'credits.section-6.elements.element-2.title',
                },
                {
                  key: 3,
                  title: 'credits.section-6.elements.element-3.title',
                },
                {
                  key: 4,
                  title: 'credits.section-6.elements.element-4.title',
                },
                {
                  key: 5,
                  title: 'credits.section-6.elements.element-5.title',
                },
                {
                  key: 6,
                  title: 'credits.section-6.elements.element-6.title',
                },
                {
                  key: 7,
                  title: 'credits.section-6.elements.element-7.title',
                },
                {
                  key: 8,
                  title: 'credits.section-6.elements.element-8.title',
                },
                {
                  key: 9,
                  title: 'credits.section-6.elements.element-9.title',
                },
                {
                  key: 10,
                  title: 'credits.section-6.elements.element-10.title',
                },
                {
                  key: 11,
                  title: 'credits.section-6.elements.element-11.title',
                },
                {
                  key: 12,
                  title: 'credits.section-6.elements.element-12.title',
                },
                {
                  key: 13,
                  title: 'credits.section-6.elements.element-13.title',
                },
                {
                  key: 14,
                  title: 'credits.section-6.elements.element-14.title',
                },
                {
                  key: 15,
                  title: 'credits.section-6.elements.element-15.title',
                },
                {
                  key: 16,
                  title: 'credits.section-6.elements.element-16.title',
                },
                {
                  key: 17,
                  title: 'credits.section-6.elements.element-17.title',
                },
                {
                  key: 18,
                  title: 'credits.section-6.elements.element-18.title',
                },
                {
                  key: 19,
                  title: 'credits.section-6.elements.element-19.title',
                },
                {
                  key: 20,
                  title: 'credits.section-6.elements.element-20.title',
                },
                {
                  key: 21,
                  title: 'credits.section-6.elements.element-21.title',
                },
              ]
            },
            {
              key: 7,
              title: 'credits.section-7.title',
              text: 'credits.section-7.text',
              elements: [
                {
                  key: 1,
                  title: 'credits.section-7.elements.element-1.title',
                  subtitle: 'credits.section-7.elements.element-1.subtitle',
                },
                {
                  key: 2,
                  title: 'credits.section-7.elements.element-2.title',
                  subtitle: 'credits.section-7.elements.element-2.subtitle',
                },
                {
                  key: 3,
                  title: 'credits.section-7.elements.element-3.title',
                  subtitle: 'credits.section-7.elements.element-3.subtitle',
                },
                {
                  key: 4,
                  title: 'credits.section-7.elements.element-4.title',
                  subtitle: 'credits.section-7.elements.element-4.subtitle',
                },
                {
                  key: 5,
                  title: 'credits.section-7.elements.element-5.title',
                  subtitle: 'credits.section-7.elements.element-5.subtitle',
                }
              ]
            }
          ]
        }
    }
}
</script>

<style lang="css" scoped>
.highlight {
  color: #1560BD ;
  font-weight: 500 ;
  font-size: 110%
}
</style>