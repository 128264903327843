import Homepage from '@/views/Homepage'
import Storytelling from '../../views/storytelling/Storytelling'
import Edition from "../../views/edition/Edition"
import Browse from '@/views/edition/Browse'
import About from "@/views/about/About"
import Visualizations from '../../views/visualizations/Visualizations'
import Docs from "@/views/about/Docs"
import Contacts from '@/views/Contacts'
import Credits from '@/views/Credits'
import Terms from '@/views/Terms'

import breadcrumbs from './breadcrumbs'

export const routes = [
        {
            name: 'Home',
            path: '/', 
            component: Homepage,
        },
        {
            name: 'Aldo Moro',
            path: '/aldomoro', 
            component: Storytelling,
            meta: {
                breadcrumbs: breadcrumbs.storytelling,
            }
        },
        {
            name: 'Opere',
            path: '/edition', 
            component: Edition,
            meta: {
                breadcrumbs: breadcrumbs.edition,
            }
        },
        {
            name: 'Naviga Opere',
            path: '/edition/browse', 
            component: Browse,
            children: [
                {
                    name: 'Indici analitici',
                    path: 'indexes',
                    component: () => import('@/views/edition/indexes/Indexes'),
                    meta: {
                        breadcrumbs: breadcrumbs.indexes,
                    }                                            
                },
                {
                    name: 'Indice',
                    path: 'indexes/(people|places|organizations|bibliography)',
                    component: () => import('@/views/edition/indexes/index/SelectedIndex'),
                    meta: {                             
                        dynamic_b: true,
                        breadcrumbs: {
                            people: breadcrumbs.people,
                            places: breadcrumbs.places,
                            organizations: breadcrumbs.organizations,
                            bibliography: breadcrumbs.bibliography
                        }                                                                                             
                    }
                },
                {                
                    name: 'Contenuti',
                    path: 'editorial_plan',
                    component: () => import('@/views/edition/plan/EditorialPlan'),
                    props: true,
                    meta: {
                        breadcrumbs: breadcrumbs.editorial_plan
                    },
                    children: [
                        {
                            name: 'Elenco Opere',
                            path: ':worksPath',
                            component: () => import('@/components/collection/WorksIndex.vue'),
                            props: true,
                            meta: {
                                breadcrumbs: breadcrumbs.editorial_plan
                            }
                        } 
                    ]                
                },
                {
                    name: 'Ricerca avanzata',
                    path: 'advance_search',
                    component: () => import('@/views/edition/advanceSearch/AdvanceSearch'),
                    meta: {
                        breadcrumbs: breadcrumbs.advance_search
                    }
                },
                {
                    name: 'Opera',
                    path: 'work/:id',
                    component: () => import('@/views/edition/work/Work'),
                    meta: {
                        breadcrumbs: breadcrumbs.work
                    }
                }
            ]            
        },                
        {
            name: 'Progetto',
            path: '/about', 
            component: About,
            meta: {
                breadcrumbs: breadcrumbs.about
            }
        },
        {
            name: 'Documentazione',
            path: '/about/docs', 
            component: Docs,
            children: [
                {
                    name: 'Il flusso di lavoro',
                    path: 'workflow',
                    component: () => import('@/views/about/docs/Workflow'),
                    meta: {
                        breadcrumbs: breadcrumbs.workflow
                    }
                },
                {
                    name: 'I criteri editoriali',
                    path: 'principles',
                    component: () => import('@/views/about/docs/Principles'),
                    meta: {
                        breadcrumbs: breadcrumbs.principles
                    }
                },
                {
                    name: 'La modellazione dei dati',
                    path: 'models',
                    component: () => import('@/views/about/docs/Models'),
                    meta: {
                        breadcrumbs: breadcrumbs.models
                    }
                },
                {
                    name: 'L\'elaborazione dei documenti',
                    path: 'processing',
                    component: () => import('@/views/about/docs/Processing'),
                    meta: {
                        breadcrumbs: breadcrumbs.processing
                    }
                },
                {
                    name: 'Il sito dell\'Edizione',
                    path: 'site',
                    component: () => import('@/views/about/docs/Site'),
                    meta: {
                        breadcrumbs: breadcrumbs.site
                    }
                },
                {
                    name: 'I risultati scientifici',
                    path: 'results',
                    component: () => import('@/views/about/docs/Results'),
                    meta: {
                        breadcrumbs: breadcrumbs.results
                    }
                },
            ],
        },
        {
            name: 'Visualizzazioni',
            path: '/visualizations', 
            component: Visualizations,
            meta: {
                breadcrumbs: breadcrumbs.visualizations                
            }
        },
        {
            name: 'Contatti',
            path: '/contacts', 
            component: Contacts,
            meta: {
                breadcrumbs: breadcrumbs.contacts
            }
        },
        {
            name: 'Crediti',
            path: '/credits',
            component: Credits,
            meta: {
                breadcrumbs: breadcrumbs.credits
            }                                            
        },
        {
            name: "Termini d'utilizzo",
            path: '/terms',
            component: Terms,
            meta: {
                breadcrumbs: breadcrumbs.terms
            }                                            
        },
        {
            name: '404',
            path: '/:notFound(.*)', 
            component: () => import('@/views/NotFound'),
            meta: {
            }
        }
    ]
    


