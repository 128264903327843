import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'


import { routes } from './utils/routes'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Edizione Nazionale delle Opere di Aldo Moro';

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0
    //console.log(to,from);
    if (to.hash) {
      if (to.hash.includes('#')) {        
        // IF SAME PAGE
        // to.hash !== from.hash
        if (to.path === from.path) {
          if(to.hash === from.hash){
            return {
              selector: to.hash,
              behavior: 'smooth',
              offset: { x: 0, y: 100 }
            }
          }else{
            return
          }
        } 
        else {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                selector: to.hash,
                behavior: 'smooth',
                offset: { x: 0, y: 100 }
              })
            }, 500)
          })
        }
      }
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes
})

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router