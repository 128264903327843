<template>
    <v-container>
            <v-carousel
            hide-delimiters
            show-arrows-on-hover
            height="350"
            >
                <v-carousel-item
                contain
                v-for="item in items"
                :key="item.src"
                :src="require('@/assets/about/' + $t(item.src) + '')"
                ></v-carousel-item>
            </v-carousel>
    </v-container>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => []
        }
    }
}
</script>