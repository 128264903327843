import axios from 'axios'

export default {
    async loadWorks(context,payload) {        
        const filters = payload;
        let paramsFilter = {};

        let lists = ['doctopicList','doctypeList','roleList'] 
        for(let listElement of lists) {
            if(filters[listElement]){
                paramsFilter[listElement] = filters[listElement].map(element => element.id)
            }
        }

        paramsFilter = {
            ...paramsFilter,
            docstatus: filters.docstatus,                    
            ident: filters.path            
        }
             
        if(!filters.selAllDate){
            paramsFilter = {
                ...paramsFilter,
                date: filters.date
            }
        }
        
        const response = await axios.get(`/api/works`, { params: paramsFilter })
        const responseData = response.data        
        const works = [];

        responseData.forEach(w => {
             const splitIdent = w.ident.split('_');

            const work = {
                ...w,
                section: splitIdent[0],
                volume: splitIdent[1],
                tome: splitIdent[2],
                documentNumber: splitIdent[3],        
            };
            works.push(work);
        })        
                
        context.commit('setWorks', works)
    },
}