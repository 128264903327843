<template>
    <v-alert
    class="mx-4"
    border="left"
    outlined
    text
    :prominent="$vuetify.breakpoint.mdAndUp"
    :dense="$vuetify.breakpoint.mdAndDown"
    type="info"
    elevation=2
    icon="mdi-text-box-plus"
    >{{ $t(introSeeAlso) }}
        <a
        target="_blank"
        :href="$t(link)">
        {{ $t(seeAlso) }}
        </a>
    </v-alert>
</template>

<script>
export default {
    props: ['introSeeAlso', 'seeAlso', 'link'],
}
</script>