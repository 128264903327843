export default {
    namespaced: true,
    state: {
        lastIndex: '',
        search: '',
        path: [],
        date: [1930, 1978],
        selAllDate: true,
    },
    getters: {
        lastIndex(state) {
            return state.lastIndex
        },
        search({ search }) {
            return search
        },
        path(state) {
            return state.path
        },
        date(state) {
            return state.date
        },
        selAllDate(state) {
            return state.selAllDate
        },
        filters({ path, date, selAllDate }) {
            return {
                path,
                date,
                selAllDate
            }
        }
    },
    actions: {
        updateDate(context, payload) {
            return context.commit('changeDate', payload)
        },
        updateSelAllDate(context, payload) {
            return context.commit('changeSelAllDate', payload)
        },
        updatePath(context, payload) {
            return context.commit('changePath', payload)
        },
        updateSearch(context, payload) {
            return context.commit('changeSearch', payload)
        },
        resetFilters(context) {
            return context.commit('resetFilters')
        },
        async removeFilter(context,removedFilter){   
            if(removedFilter.category === "title") await context.dispatch('updateSearch','');
            else{
                if(removedFilter.category === "date") {
                    await context.dispatch('updateSelAllDate',true)                    
                }else{                    
                    context.commit('removeFilter', removedFilter)                
                }                         
            }
        },
    },
    mutations: {        
        changeDate(state, newDate) {
            return state.date = newDate
        },
        changeSelAllDate(state, newSelAllDate) {
            console.log('newSelAllDate',newSelAllDate);
            return state.selAllDate = newSelAllDate
        },
        changePath(state, newPath) {
            return state.path = newPath
        },
        changeSearch(state, newSearch) {
            return state.search = newSearch
        },        
        resetFilters(state) {
            state.search = '';
            state.path = [];
            state.date = [1930,1978]
            state.selAllDate = true            
            return state
        },
        removeFilter(state,removedFilter){                        
            for(let selectedFilter in state){          
                if(selectedFilter !== "selAllDate" && selectedFilter !== "search" && selectedFilter !== "lastIndex") state[selectedFilter] = state[selectedFilter].filter(filter => {
                    let filterValue = filter.id ? filter.id : filter
                    return filterValue != removedFilter.value
                })                
            }
        }
    }
}