export default {
    // change path id on the step position in the stepperPath array 
    changeStepperPath(state,data){ 
        console.log('data',data);
        console.log(state.stepperPath);
        state.stepperPath[data.step - 1] = data.id
    },
    // clear stepperPath changing the length based on the step the user went back on
    clearPath(state,step){
        state.stepperPath.length = step    
    },
    // push the new filtered volumes inside the volumes array
    pushVolumes(state,data){        
        state.volumes = data.volumes                      
    },
    // push the new filtered tomes inside the tomes array
    pushTomes(state,data){
        state.tomes = data.tomes
    }
}