<template>
  <v-container>
    <base-section-heading title="contacts.title"> </base-section-heading>
    <Section title="contacts.subtitle" text="contacts.text"></Section>
    <v-card class="mt-3 mb-3" elevation="2">
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="sendEmail">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="name"
                name="name"
                :rules="nameRules"
                :counter="30"
                :label="$t('contacts.form.name')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                name="email"
                v-model="email"
                :rules="emailRules"
                :label="$t('contacts.form.email')"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-textarea
            v-model="message"
            outlined
            name="message"
            :rules="messageRules"
            :label="$t('contacts.form.message')"
            required
          ></v-textarea>
          <div class="d-flex">
            <div class="mr-auto">
              <v-alert
                v-if="alert.success"
                type="success"
                class="ml-4 mr-2"                
                dense
                text
                >Messaggio inviato con successo. Grazie per averci contattato,
                vi risponderemo al più presto.</v-alert
              >
              <v-alert
                v-else-if="alert.error"
                type="error"
                class="ml-4 mr-2"                
                dense
                text
                >Non è stato possibile inviare il messaggio. Ci scusiamo per il
                disagio, è possibile contattarci anche agli indirizzi che
                trovate qui sotto.</v-alert
              >
            </div>            
            <v-btn
              type="submit"
              class="mr-4"
              :loading="isLoading"
              :disabled="!valid || isLoading"
              color="primary"
            >
              {{ $t(contacts.send) }}
            </v-btn>
            <vue-recaptcha
              ref="invisibleRecaptcha"
              @verify="onVerify"
              @expired="onExpired"
              size="invisible"
              :sitekey="sitekey"
            >
            </vue-recaptcha>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="mt-3">
      <v-card-text>
        {{ $t(contacts.text2) }}
        <v-row>
          <v-col v-for="contact in contacts.contactInfo" :key="contact.key">
            <v-card>
              <v-card-title>
                {{ $t(contact.title) }}
              </v-card-title>
              <v-card-subtitle>
                {{ $t(contact.subtitle) }}
              </v-card-subtitle>
              <v-card-text v-html="$t(contact.text)"> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Section from "@/components/docs/Section";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";

export default {
  components: {
    Section,
    VueRecaptcha,
  },
  data() {
    return {
      sitekey: process.env.VUE_APP_CAPTCHA_KEY,
      isLoading: false,
      alert: {
        error: false,
        success: false,
      },
      valid: false,
      contacts: {
        subtitle: "contacts.subtitle",
        text: "contacts.text",
        text2: "contacts.text-2",
        send: "contacts.form.send",
        contactInfo: [
          {
            key: 1,
            title: "contacts.contactInfo.contact-1.title",
            subtitle: "contacts.contactInfo.contact-1.subtitle",
            text: "contacts.contactInfo.contact-1.text",
          },
          {
            key: 2,
            title: "contacts.contactInfo.contact-2.title",
            subtitle: "contacts.contactInfo.contact-2.subtitle",
            text: "contacts.contactInfo.contact-2.text",
          },
        ],
      },
      name: "",
      nameRules: [
        (v) => !!v || this.$t("contacts.form.nameAlert"),
        (v) => (v && v.length <= 20) || this.$t("contacts.form.nameCharacters"),
      ],
      email: "",
      emailRules: [
        v => !!v || this.$t("contacts.form.emailAlert"),
        v => /.+@.+\..+/.test(v) || this.$t("contacts.form.emailValidity"),        
      ],
      message: "",
      messageRules: [(v) => !!v || this.$t("contacts.form.messageAlert")],
      //checkbox: false,
    };
  },
  methods: {
    async onVerify(response) {
      this.isLoading = true;
      this.$refs.invisibleRecaptcha.reset();
      await axios
        .post(`/send`, {
          name: this.name,
          email: this.email,
          message: this.message,
          recaptchaToken: response,
        })
        .then(() => {
          this.alert.success = true;
        })
        .catch((err) => {
          console.log(err)
          this.alert.error = false;
        });
      this.isLoading = false;
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();
    },
    async sendEmail() {      
      await this.$refs.invisibleRecaptcha.execute();
    },    
  },  
};
</script>

<style lang="scss">
    .grecaptcha-badge { 
    z-index: 1;
}
</style>