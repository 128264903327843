export default {
    people(state){
        return state.indexes.people
    },
    places(state){
        return state.indexes.places
    },
    organizations(state){
        return state.indexes.organizations
    },
    bibliography(state){
        return state.indexes.bibliography
    },
    index(state){
        return state.index
    }
}