<template>
  <v-list v-model="selectedItem" dense>
    <!--<v-list-item-group v-model="selectedItem" mandatory color="primary">-->
      <DocDrawerListGroup
        v-for="group in groups"
        :key="group.id"
        :group="group"
        @route-doc="routeDoc($event)"
      />
    <!-- </v-list-item-group> -->
  </v-list>
</template>

<script>
import DocDrawerListGroup from "./DocDrawerListGroup";

export default {
  components: {
    DocDrawerListGroup,
  },
  props: ["groups"],
  data: () => ({
    selectedItem: null,
  }),
  methods: {
    //HARD CODED Routing on list-group-head click
    routeDoc(id) {
      if (this.$route.path === this.groups[id].to) return;

      this.$router.push(this.groups[id].to);
    },
  },
  watch: {
    // $route (to){
    //   if(!to.hash.includes('#')){
    //     for(let group of this.groups){
    //       if(group.to === to.path) return this.selectedItem = group.id
    //     }
    //   }else{
    //     for(let group of this.groups){
    //       for(let child of group.children){            
    //         if(to.hash.includes(child.id)) return this.selectedItem = child.selected
    //       }
    //     }
    //   }
    // },
    selectedItem(val) {
      console.log(val);
    }
    // selectedItem(val) {   
    //   if(!this.activeItem){
    //     if (this.groups[this.selectedItem]) {
    //     if (this.$route.path === this.groups[this.selectedItem].to) return;

    //     const iterator = this.groups.keys();
    //     for (const key of iterator) {
    //       if (this.$route.path === this.groups[key].to)
    //         return (this.selectedItem = key);           
    //     }
    //   }
    //   return;
    //   }      
    // },
    // activeItem(id) {
    //   if(id) return this.selectedItem = this.activeItem
    // }
  },
  mounted(){
    if(!this.$route.hash.includes('#')){
        for(let group of this.groups){
          
          if(group.to === this.$route.path){
            console.log(group);
            return group.active = true;
          } 
          //return this.selectedItem = group.id
        }
      }
  }
};
</script>