<template>
  <section>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <doc-navigation-drawer :activeID="selectedID"> </doc-navigation-drawer>
      <v-container id="container">
        <!--fluid-->
        <v-row>
          <v-col class="col">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-container id="container">
        <!--fluid-->
        <v-row>
          <v-col class="col">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
      <!-- <div style="height:56px; width:100%;">

    </div> -->
      <bottom-navigation-bar :items="bottomNavigationItems"> </bottom-navigation-bar>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      selectedID: null,
      bottomNavigationItems: [
        {
          title: "about.workflow.title",
          icon: "mdi-arrow-decision-auto",
          to: "/about/docs/workflow",
        },
        {
          title: "about.principles.title",
          icon: "mdi-book",
          to: "/about/docs/principles",
        },
        {
          title: "about.models.title",
          icon: "mdi-cube",
          to: "/about/docs/models",
        },
      ],
    };
  },
  methods: {
    changeActiveLink() {
      let offsets = [];

      document.getElementsByClassName("section").forEach((section) => {
        const id = section.id;
        const top = section.getBoundingClientRect().top;

        const data = {
          id,
          top,
        };
        offsets.push(data);
      });

      const selectedID = offsets.filter((offset) => {
        const top = offset.top;
        if (top <= 120) return true;
        return false;
      });

      if (selectedID.length < 0) {
        return null;
      }

      let closest = { id: "", top: 0 };

      for (let i = 0; i < selectedID.length; i++) {
        if (closest.top === 0) {
          closest = selectedID[i];
        } else if (
          selectedID[i].top > 0 &&
          selectedID[i].top <= Math.abs(closest.top)
        ) {
          closest = selectedID[i];
        } else if (
          selectedID[i].top < 0 &&
          -selectedID[i].top < Math.abs(closest.top)
        ) {
          closest = selectedID[i];
        }
      }

      if (closest.id !== "" && this.$route.hash !== "#" + closest.id) {
        return this.$router.replace("#" + closest.id);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.changeActiveLink);
  },
};
</script>

<style lang="scss">
</style>