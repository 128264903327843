import axios from 'axios'

export default {
    async loadIndexes(context, payload){
        let index;        
        if(!payload.filters.selAllDate) payload.filters.selAllDate = null
        
        try{
            const response = await axios.get(`/api/indices/${payload.indexName}`, {params: payload.filters})
            index = response.data
        }catch(err){
            console.log(err)
        }                        
        context.commit('copyIndexes', index)
    }
}