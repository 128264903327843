<template>
  <v-bottom-navigation
    id="bottomNav"
    :value="value"
    :fixed="isFixed"
    color="primary"
  >
    <v-btn v-for="item in items" :key="item.title" :to="item.to">
      <span>{{ $t(item.title) }}</span>

      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      value: null,
      isFixed: false,
    };
  },
  methods: {
    toggleSticky() {
    //   const paddingBottom = document.getElementsByClassName("v-main")[0].style
    //     .paddingBottom;
        const bottomNavRect = document
        .getElementById("bottomNav")
        .getBoundingClientRect();
      const footerRect = document
        .getElementById('footer')
        .getBoundingClientRect()    
      const windowHeight = window.innerHeight;
    //   if(footerRect.top <= windowHeight || footerRect.top <= windowHeight) return this.isFixed = false
    //     else{
    //         if(!(footerRect.top - bottomNavRect.top <= 0) || !(bottomNavRect.top - footerRect.top <= 0))  return this.isFixed = true            
    //     } 
        if(footerRect.top + bottomNavRect.height <= windowHeight || footerRect.top <= windowHeight) return this.isFixed = false
        else{
            if(!(footerRect.top - bottomNavRect.top <= 0) || !(bottomNavRect.top - footerRect.top <= 0))  return this.isFixed = true            
        }    

    },
  },
  mounted() {
    // Right panel handling size and position
    this.toggleSticky()
    window.addEventListener("scroll", this.toggleSticky);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleSticky);
  },
};
</script>

<style lang="scss">

</style>