export default { 
    state: {
        browsingState: '/edition'         
       },   
    mutations:{
        changeBrowisingState(state,newPath){
            return state.browsingState = newPath
        }
    },
    actions:{
        changeBrowisingState(context,path){            
            context.commit('changeBrowisingState',path)
        }
    },
    getters: {
        browsingState(state){
            return state.browsingState
        }
    }
}