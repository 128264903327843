export default {
    state:{
        show: false,
        text: '',
        color: '',        
    },
    getters:{
        show(state) {
            return state.show
        },
        text(state){
            return state.text
        },
        color(state){
            return state.color
        }
    },
    actions:{
        setSnackbar(context,data) {
            const text = data.text;
            const color = data.color;

            context.commit('changeText',text);
            context.commit('changeColor',color);

            return context.commit('showSnackbar')
        },
        hideSnackbar(context){
            return context.commit('hideSnackbar')
        }
    },
    mutations:{
        changeText(state,newText) {
            return state.text = newText;
        },
        changeColor(state,newColor) {
            return state.color = newColor;
        },
        showSnackbar(state) {            
            return state.show = true
        },
        hideSnackbar(state) {            
            return state.show = false
        }
    }
}