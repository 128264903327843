<template>
  <v-container class="pa-4 text-center">
    <v-row class="fill-height" align="center" justify="center">
      <template v-for="(item, i) in items">
        <v-col :key="i" :cols="cols" :md="md" :sm="sm" :lg="lg" :xl="xl">
          <v-hover v-slot="{ hover }">
            <v-card :to="item.to" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-img
                :src="require('@/assets' + item.img)"
                :height="resizeHeight"
                :title="item.imgTitle ? $t(item.imgTitle) : ''"
                gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
              >
                <v-responsive
                  class="d-flex align-center mx-auto white--text pa-2"
                  height="100%"
                  max-width="900"
                  width="100%"
                >
                  <base-subheading weight="regular" :title="item.text" />

                  <base-heading
                    size="text-h3"
                    :title="item.title"
                    weight="medium"
                  />

                  <base-body>
                    {{ $t(item.subtext) }}
                  </base-body>
                  <div class="align-self-center element-to-stick-to-bottom">
                    <base-btn v-show="height > 400" :to="item.to"> Scopri </base-btn>
                  </div>
                </v-responsive>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>  

<script>
export default {
  props: {    
    items: {
      type: Array,
      default: () => [] 
    },
    height: {
      type: Number,
      default: 1
    },
    cols: {
      type: Number,
      default: 12
    },
    md: {
      type: Number,
      default: 4,
    },
    sm: {
      type: Number,
      default: 12
    },
    lg: {
      type: Number,
      deafault: 4
    },
    xl: {
      type: Number,
      default: 3,
    }
  },
  computed: {
    resizeHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400/this.height;
        case "md":
          return 650/this.height;
        case "lg":
          return 670/this.height;
        case "xl":
          return 1000/this.height;
        default:
          return 670/this.height;
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>