import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';
import i18n from './translation/i18n'
import router from './router/index'
import store from './store/index'
import './plugins' //Authomatically import and load base components

import NavigationDrawer from '@/components/navigation/drawer/NavigationDrawer'
import DocNavigationDrawer from '@/components/navigation/doc-drawer/DocNavigationDrawer'
import BottomNavigationBar from '@/components/navigation/BottomNavigationBar'
import WorkCard from '@/components/collection/works/WorkCard'
import LinearLoading from '@/components/loading/LinearLoading'

// Inizio test

//Leaflet
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


//Fine test

//Imported components
Vue.component('navigation-drawer',NavigationDrawer)
Vue.component('doc-navigation-drawer', DocNavigationDrawer)
Vue.component('bottom-navigation-bar',BottomNavigationBar)
Vue.component('work-card',WorkCard)
Vue.component('linear-loading',LinearLoading)


//import VueI18n from 'vue-i18n' //Translation library

Vue.config.productionTip = false
Vue.use(VueGtag, {
  config: { id: "G-7YG6Y4KZ6B"}
  // appName: 'AldoMoroDigitale',
  // pageTrackerScreenviewEnabled: true
}, router)
Vue.use(VueMeta)
Vue.use(VueCookies)

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
