import axios from 'axios'

export default {
    async updateWork(context,payload) {
        const split = payload.split('_');
        const ident = split[0];
        const metaId = split[1];

        const response = await axios.get('/api/works/select', {
            params: {
                ident,
                metaId
            }
        })

        const work = response.data

        context.commit('updateWork',work);        
    },
    
    async formatCategoryPanel(context) {
        const categories = context.getters['getCategories'];
        const categoriesStructure = context.getters['getCategoriesStructure'];

        categoriesStructure.map(category => {          
            if(categories[category.id].length > 0) category.entities = categories[category.id]
            else category.entities = []
        })

        context.commit('updateCategoriesStructure', categoriesStructure)
    }
}