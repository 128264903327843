import Vue from 'vue'
import Vuex from 'vuex'

import snackbarModule from './snackbar'
import browseModule from './browse'
import indexesModule from './indexes'
import indexFiltersModule from './indexes/indexFilters'
import indexSortingModule from './indexes/indexSorting'
import editorialModule from './editorial-plan'
import descriptionsModule from './editorial-plan/descriptions'
import filtersModule from './filters'
import worksModule from './works'
import workModule from './works/work'



Vue.use(Vuex)

const store =  new Vuex.Store({
  modules:{
    snackbar: snackbarModule,
    browse: browseModule,
    indexes: indexesModule,
    indexFilters: indexFiltersModule,
    indexSorting: indexSortingModule,
    editorialPlan: editorialModule,    
    descriptions: descriptionsModule,
    filters: filtersModule,
    works: worksModule,
    work: workModule
  }  
})

export default store